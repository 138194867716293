<!-- Downloads toggle -->
<button mat-icon-button (click)="openPanel()" #downloadsOrigin>
    <mat-icon [svgIcon]="'nav_downloads_icon'" style="padding: 3px;"></mat-icon>
</button>

<!-- Downloads panel -->
<ng-template #downloadsPanel>

    <div
        class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">

        <!-- Header -->
        <div class="flex shrink-0 items-center py-4 pr-4 pl-6 bg-primary text-on-primary">
            <div class="sm:hidden -ml-1 mr-3">
                <button mat-icon-button (click)="closePanel()">
                    <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                </button>
            </div>
            <div class="text-lg font-medium leading-10">Downloads</div>
            <!-- <div class="ml-auto">
                <button mat-icon-button [matTooltip]="'Refresh all downloads'" (click)="refreshAll()">
                    <mat-icon class="icon-size-5 text-current" [svgIcon]="'mat_outline:refresh'"></mat-icon>
                </button>
            </div> -->
        </div>

        <!-- Content -->
        <div class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card">
            <!-- Downloads -->
            <ng-container *ngFor="let download of downloads; trackBy: trackByFn">
                <div class="flex group hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5">


                    <ng-container>
                        <div class="flex flex-auto py-5 pl-6">
                            <ng-container *ngTemplateOutlet="downloadContent"></ng-container>
                        </div>
                    </ng-container>

                    <!-- Actions -->
                    <div class="relative flex flex-col my-5 mr-5 ml-2">

                        <!-- Remove -->
                        <!-- <button
                            class="w-6 h-6 min-h-6"
                            mat-icon-button
                            (click)="deleteReport(download)"
                            [matTooltip]="'Delete downloads'">
                            <mat-icon
                                class="icon-size-4"
                                [svgIcon]="'mat_outline:delete'"></mat-icon>
                        </button> -->

                        <!-- Download -->
                        <button *ngIf="download.download_url && !download.isDownloading" class="w-8 h-8 min-h-8"
                            mat-icon-button (click)="downloadReport(download)" [matTooltip]="'Download report'">
                            <mat-icon class="icon-size-5" [svgIcon]="'mat_outline:file_download'"></mat-icon>
                        </button>
                        <mat-spinner *ngIf="download.isDownloading" class="w-4 h-4 m-2"></mat-spinner>
                    </div>

                </div>

                <!-- Download content template -->
                <ng-template #downloadContent>
                    <!-- Title, description & time -->
                    <div class="flex flex-col flex-auto">
                        <ng-container *ngIf="download.title">
                            <div class="font-semibold" [innerHTML]="download.title"></div>
                        </ng-container>
                        <ng-container *ngIf="download?.status">
                            <!-- class="line-clamp-2" -->
                            <div [ngClass]="(download.status == 'completed') ? 'text-green-600' : ''">
                                {{download.status == 'pending' ? 'In Queue' :
                                download.status == 'completed' ? 'Ready To Download' : 'Error' | titlecase}}</div>
                        </ng-container>
                        <div class="mt-2 text-sm leading-none text-secondary">
                            {{download.created_at | date:'MMM dd yyyy, h:mm a'}}
                        </div>
                        <div class="mt-2 text-sm leading-none text-warn" *ngIf="download.downloadError">
                            Download error. Please try again.
                        </div>
                    </div>
                </ng-template>
            </ng-container>

            <!-- <ng-container>
                <div class="flex justify-center pt-4 pb-4" *ngIf="!showLoading && hasMoreItems">
                    <a class="flex flex-col p-1 rounded-2xl  cursor-pointer" (click)="loadNextPage()">
                        <div class="flex flex-col flex-auto justify-center text-center text-sm font-medium">
                            <div class="truncate">Load More</div>
                        </div>
                    </a>
                    <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'" *ngIf="showLoading">
                    </mat-progress-spinner>
                </div>
            </ng-container> -->


            <!-- No downloads -->
            <ng-container *ngIf="!downloads || !downloads.length">
                <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                    <div
                        class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100 dark:bg-primary-600">
                        <mat-icon class="text-primary-700 dark:text-primary-50"
                            [svgIcon]="'mat_outline:file_download'"></mat-icon>
                    </div>
                    <div class="mt-5 text-2xl font-semibold tracking-tight">No downloads</div>
                    <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">When you have downloads,
                        they will appear here.</div>
                </div>
            </ng-container>

        </div>

    </div>

</ng-template>