import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { InitialData } from './event-type.types';
import { EventConfigurationService } from 'app/core/service/event/event-config.service';

@Injectable({
    providedIn: 'root'
})
export class SafetyEventTypeDataResolver 
{
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _eventService: EventConfigurationService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InitialData> {
        return this._eventService.getEventCategoriesInitial()
            .pipe(
                map((events) => ({
                    events
                })),
                catchError(err => {
                    this._eventService.errorMessage = err?.error?.message ? err?.error?.message : "Something went wrong !";
                    return of(new DefaultResponse().events);
                })
            );

    }

}
class DefaultResponse implements InitialData {
    events: any = [];
}