import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EventConfigurationService } from 'app/core/service/event/event-config.service';
import { ProjectService } from 'app/core/service/project/project.service';
import { OrganizationService } from 'app/core/service/organization/organization.service';
import { LocalStorageService } from 'app/core/service/localstorage/local-storage.service';
import { EventService } from 'app/core/service/event/event.service';

@Injectable({
    providedIn: 'root'
})
export class SnagChartDataResolver 
{
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient,
        private _projectService: ProjectService,
        private _eventService: EventConfigurationService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        this._eventService.clearData()
        return forkJoin([
            this._projectService.getProjects(0, 100, null, null, null, true),
            this._eventService.getEventCategories(0, 100, null, null, null, true)
        ])

    }

}