import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of, forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { InitialData } from './support-ticket-detail.types';
import { DocumentService } from 'app/core/service/document/document.service';
import { SupportTicketService } from 'app/core/service/support-ticket/support-ticket.service';

@Injectable({
    providedIn: 'root'
})
export class SupportTicketDetailDataResolver 
{
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _supportTicketService: SupportTicketService,
        private _documentService: DocumentService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return forkJoin([
            this._supportTicketService.getSupportTicketDetails(route.params['id'])
        ]);
    }

}
class DefaultResponse implements InitialData {
    supportTicketDetails: any = {};
}