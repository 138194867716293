import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { InitialData } from './root-cause.types';
import { AlertService } from 'app/core/service/alert/alert.service';
import { EventConfigurationService } from 'app/core/service/event/event-config.service';

@Injectable({
    providedIn: 'root'
})
export class RootCauseDataResolver 
{
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _rootCauseService: EventConfigurationService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

        return forkJoin([
            this._rootCauseService.getRootCausesInitial(),
            this._rootCauseService.getEventCategoriesInitial(),
        ])

        // return this._rootCauseService.getRootCausesInitial()
        // .pipe(
        //     map((alerts) => ({
        //         alerts
        //     })
        //     ),
        //     catchError(err => {                
        //         this._rootCauseService.errorMessage = err?.error?.message ? err?.error?.message : "Something went wrong !";
        //         return of(new DefaultResponse().rootCauses);
        //     })
        // );
        
    }

}
class DefaultResponse implements InitialData {
    rootCauses: any = [];
}