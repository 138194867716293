import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of, forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { OrganizationService } from 'app/core/service/organization/organization.service';
import { InitialData } from './organization-detail.types';

@Injectable({
    providedIn: 'root'
})
export class OrganizationDetailDataResolver 
{
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _organizationService: OrganizationService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return forkJoin([
            this._organizationService.getOrganizationDetails(route.params['id'])
                .pipe(
                    map((organizationDetails) => ({
                        organizationDetails
                    })
                    ),
                    catchError(err => {
                        return of(new DefaultResponse().organizationDetails);
                    })
                ),
            this._organizationService.getAdminsInitial(route.params['id'])
                .pipe(
                    map((admins) => ({
                        admins
                    })
                    ),
                    catchError(err => {
                        this._organizationService.errorMessage = err?.error?.message ? err?.error?.message : "Something went wrong !";
                        return of(new DefaultResponse().admins);
                    })
                ),
            this._organizationService.getConfigurations(route.params['id'])
                .pipe(
                    map((configs) => ({
                        configs
                    })
                    ),
                    catchError(err => {
                        this._organizationService.errorMessage = err?.error?.message ? err?.error?.message : "Something went wrong !";
                        return of(new DefaultResponse().configs);
                    })
                )
        ]);
    }

}
class DefaultResponse implements InitialData {
    organizationDetails: any = [];
    admins: any = [];
    configs: any = {}
}