import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationItem, FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Organization, User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import { PWANavigation } from 'app/shared/navigation';
import { LocalStorageService } from 'app/core/service/localstorage/local-storage.service';
import { OrganizationService } from 'app/core/service/organization/organization.service';
import { EventConfigurationService } from 'app/core/service/event/event-config.service';
import { EventCategory } from 'app/core/service/event/event.model';

@Component({
    selector: 'classy-layout',
    templateUrl: './classy.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: FuseNavigationItem[]
    user: User;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _userService: UserService,
        private _config: EventConfigurationService,
        private _organizationService: OrganizationService,
        private _localStorage: LocalStorageService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService
    ) {
        switch (_localStorage.accountType) {
            case 'organization_admin': {
                this.navigation = new PWANavigation(_localStorage.organizationId).org_admin_navigation
                break
            }
            case 'super_admin': {
                this.navigation = new PWANavigation(_localStorage.organizationId).navigation
                break
            }
            default: {
                this.navigation = new PWANavigation(_localStorage.organizationId).employee_navigation
                break
            }
        }


    }
    organizationName = null
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to navigation data
        // this._navigationService.navigation$
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((navigation: Navigation) => {
        //         this.navigation = navigation;
        //     });

        // Subscribe to the user service
        this._userService.user$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((user: User) => {
                this.user = user;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });

        this.organizationName = this._localStorage.organizationName
        // if (this.organizationName != null) {
        //     this._organizationService.getOrganizationDetails(this._localStorage.organizationId).subscribe({
        //         next: response => {
        //             this.showProgressbar = true
        //             this.allocatedStorage = response?.quota?.storage_limit
        //             this.usedStorage = response?.quota?.storage_used
        //             if (this.allocatedStorage > 0)
        //                 this.storagePercentage = (this.usedStorage * 100) / this.allocatedStorage
        //         }
        //     })
        // }

        this._organizationService.organization$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((response) => {
                if (response == null)
                    return
                this.showProgressbar = true
                this.allocatedStorage = response?.quota?.storage_limit
                this.usedStorage = response?.quota?.storage_used
                if (this.allocatedStorage > 0)
                    this.storagePercentage = (this.usedStorage * 100) / this.allocatedStorage
            });

        this._config.eventCategories$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((categories: EventCategory[]) => {
                this.addCategoriesToNavigation(categories);
            });
    }

    addCategoriesToNavigation(categories: EventCategory[]) {
        let menu = this.navigation.find(navItem => navItem.id == 'database')
        if (menu != null) {
            menu.children = []
            categories?.forEach(category => {
                let categoryItem: FuseNavigationItem = {
                    id: `database.${category.id}`,
                    icon: category?.name?.replace(' ', '_').toLocaleLowerCase(),
                    title: category.name,
                    type: 'basic',
                    link: `/database/category/${category.id}`
                }
                menu.children.push(categoryItem)
            })
        }
    }

    showProgressbar = false
    allocatedStorage = 0
    usedStorage = 0
    storagePercentage = 0

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }


}
