import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { OrganizationService } from 'app/core/service/organization/organization.service';
import { LocalStorageService } from 'app/core/service/localstorage/local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class EmployeeDataResolver 
{
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _employeeService: OrganizationService,
        private _localStorage: LocalStorageService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DefaultResponse> {
        return this._employeeService.getEmployees(this._localStorage.organizationId, null, null, null, null, null)
            .pipe(
                map((employees) => ({
                    employees
                })
                ),
                catchError(err => {
                    this._employeeService.errorMessage = err?.error?.message ? err?.error?.message : "Something went wrong !";
                    return of(new DefaultResponse().employees);
                })
            );

    }

}
class DefaultResponse {
    employees: any = [];
}