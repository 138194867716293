import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { InitialData } from './manage-support-ticket.types';
import { ProjectService } from 'app/core/service/project/project.service';
import { SupportTicketService } from 'app/core/service/support-ticket/support-ticket.service';

@Injectable({
    providedIn: 'root'
})
export class SupportTicketDataResolver 
{
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _supportService: SupportTicketService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InitialData> {
        return this._supportService.getSupportTickets(null, null, null, null, null)
            .pipe(
                map((projects) => ({
                    projects
                })
                ),
                catchError(err => {
                    this._supportService.errorMessage = err?.error?.message ? err?.error?.message : "Something went wrong !";
                    return of(new DefaultResponse().supportTickets);
                })
            );

    }

}
class DefaultResponse implements InitialData {
    supportTickets: any = [];
}