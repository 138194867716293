import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as fileSaver from 'file-saver';
import { tap } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class FileService {

  constructor(private http: HttpClient) { }

  downloadFile(url: string, fileName: string): any {
    return this.http.get(url, { responseType: 'blob' }).pipe(
      tap((res) => {
        let blob: any = new Blob([res], { type: 'pdf/*; charset=utf-8' });
              const url = window.URL.createObjectURL(blob);
              fileSaver.saveAs(blob, fileName);
          return res;
      }),
  );
  // .subscribe({
  //     next: res => {
  //       let blob: any = new Blob([res], { type: 'pdf/*; charset=utf-8' });
  //       const url = window.URL.createObjectURL(blob);
  //       fileSaver.saveAs(blob, fileName);
  //       this._spinner.hide()
  //     },
  //     error: error => {
  //       this._toast.error("Something went wrong. Please try again", "Sorry")
  //     }
  //   })
  }
}