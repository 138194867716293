import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of, forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ProjectService } from 'app/core/service/project/project.service';
import { InitialData } from './project-detail.types';
import { DocumentService } from 'app/core/service/document/document.service';
import { NotificationMatrixService } from 'app/core/service/notification-matrix/notification-matrix.service';

@Injectable({
    providedIn: 'root'
})
export class ProjectDetailDataResolver 
{
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _projectService: ProjectService,
        private _documentService: DocumentService,
        private _notificationMatrix: NotificationMatrixService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return forkJoin([
            this._projectService.getProjectDetails(route.params['id']),
            this._documentService.getDocuments(route.params['id'], false, null),
            this._notificationMatrix.getNotificationMatrixesInitial(route.params['id'])
        ]);

        // return this._projectService.getProjectDetails(route.params['id'])
        //     .pipe(
        //         map((projectDetails) => ({
        //             projectDetails
        //         })
        //         ),
        //         catchError(err => {
        //             this._projectService.errorMessage = err?.error?.message ? err?.error?.message : "Something went wrong !";
        //             return of(new DefaultResponse().projectDetails);
        //         })
        //     );
    }

}
class DefaultResponse implements InitialData {
    projectDetails: any = {};
}