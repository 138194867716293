import { FuseNavigationItem } from "@fuse/components/navigation";
import { LocalStorageService } from "app/core/service/localstorage/local-storage.service";
import { ReplaySubject } from "rxjs";

export class PWANavigation {

    constructor(public organizationId: string) {

    }

    private _navigation: ReplaySubject<FuseNavigationItem[]> = new ReplaySubject<FuseNavigationItem[]>(1);
    navigation: FuseNavigationItem[] = [

        // {
        //     id: 'dashboard',
        //     title: 'Dashboard',
        //     type: 'basic',
        //     icon: 'heroicons_outline:home',
        //     link: '/dashboard'
        // },
        // {
        //     id: 'report',
        //     title: 'Reports',
        //     type: 'basic',
        //     icon: 'heroicons_outline:document-report',
        //     link: '/report'
        // },
        {
            id: 'organization',
            title: 'Organization',
            type: 'basic',
            icon: 'organization',
            link: '/organization'
        }
        // {
        //     id: 'configure',
        //     title: 'Configure',
        //     type: 'collapsable',
        //     icon: 'heroicons_outline:menu',
        //     children: [
        //         {
        //             id: 'configure.autofill',
        //             title: 'Autofill',
        //             type: 'basic',
        //             icon: 'heroicons_outline:arrow-sm-right',
        //             link: '/configuration/autofill'
        //         },
        //     ]
        // },

    ];
    org_admin_navigation: FuseNavigationItem[] = [

        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'basic',
            icon: 'dashboard',
            link: '/dashboard'
        },
        {
            id: 'reports',
            title: 'Reports',
            type: 'collapsable',
            icon: 'reports',
            children: [
                {
                    id: 'report',
                    title: 'Custom Report',
                    type: 'basic',
                    icon: 'report',
                    link: '/custom-report'
                },
                {
                    id: 'snag-chart',
                    title: 'Snag Chart',
                    type: 'basic',
                    icon: 'snag_chart',
                    link: '/snag-chart'
                },
            ]
        },
        {
            id: 'organization',
            title: 'Organization',
            type: 'collapsable',
            icon: 'organization',
            children: [
                {
                    id: 'organization-details',
                    title: 'Organization Details',
                    type: 'basic',
                    icon: 'organization_details',
                    link: `/organization/${this.organizationId}`
                },
                {
                    id: 'admins',
                    title: 'Admins',
                    type: 'basic',
                    icon: 'admin',
                    link: `/admin`
                },
                {
                    id: 'employees',
                    title: 'Employees',
                    type: 'basic',
                    icon: 'employees',
                    link: `/employee`
                },
                {
                    id: 'factory',
                    title: 'Factories',
                    type: 'basic',
                    icon: 'factories',
                    link: '/factory'
                },
                {
                    id: 'region',
                    title: 'Regions',
                    type: 'basic',
                    icon: 'region',
                    link: '/region'
                },
                {
                    id: 'project',
                    title: 'Projects',
                    type: 'basic',
                    icon: 'projects',
                    link: '/project'
                },
                {
                    id: 'organization_document',
                    title: 'Documents',
                    type: 'basic',
                    icon: 'documents',
                    link: `/document`
                },
            ]
        },
        {
            id: 'database',
            icon: 'data_management',
            title: 'Data Management',
            type: 'collapsable',
        },
        {
            id: 'configure',
            title: 'Configure',
            type: 'collapsable',
            icon: 'configuration',
            children: [
                // {
                //     id: 'configure.event_category',
                //     title: 'Categories',
                //     type: 'basic',
                //     icon: 'heroicons_outline:arrow-sm-right',
                //     link: '/configuration/event/category'
                // },
                {
                    id: 'configure.event_type',
                    title: 'Types',
                    type: 'basic',
                    icon: 'category_type',
                    link: '/configuration/event/type'
                },
                {
                    id: 'configure.event_classification',
                    title: 'Classifications',
                    type: 'basic',
                    icon: 'classification',
                    link: '/configuration/event/classification'
                },
                {
                    id: 'configure.rootcause',
                    title: 'Root Cause',
                    type: 'basic',
                    icon: 'root_cause',
                    link: '/configuration/root-cause'
                },
                // {
                //     id: 'configure.injurytype',
                //     title: 'Injury Type',
                //     type: 'basic',
                //     icon: 'heroicons_outline:arrow-sm-right',
                //     link: '/configuration/injury-type'
                // },
                {
                    id: 'configure.timetarget',
                    title: 'Time Targets',
                    type: 'basic',
                    icon: 'time_target',
                    link: '/configuration/time-target'
                },
                // {
                //     id: 'configure.notification',
                //     title: 'Notification Matrix',
                //     type: 'basic',
                //     icon: 'heroicons_outline:arrow-sm-right',
                //     link: '/configuration/notification-matrix'
                // },
            ]
        },
        {
            id: 'checklist',
            title: 'Checklist',
            type: 'collapsable',
            icon: 'heroicons_outline:clipboard-list',
            children: [
                {
                    id: 'checklist.classification',
                    title: 'Classification',
                    type: 'basic',
                    icon: 'checklist_classification',
                    link: '/checklist/classification'
                },
                {
                    id: 'checklist.checklists',
                    title: 'Checklist',
                    type: 'basic',
                    icon: 'checklist',
                    link: '/checklist/list'
                },
            ]
        },
        {
            id: 'export',
            title: 'Downloads',
            type: 'basic',
            icon: 'downloads',
            link: '/downloads'
        },
        {
            id: 'alert',
            title: 'Alerts',
            type: 'basic',
            icon: 'alert',
            link: '/alert'
        },
        {
            id: 'support',
            title: 'Support',
            type: 'basic',
            icon: 'support',
            link: '/support/ticket'
        },

    ];
    employee_navigation: FuseNavigationItem[] = [

        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'basic',
            icon: 'dashboard',
            link: '/dashboard'
        },
        {
            id: 'reports',
            title: 'Reports',
            type: 'collapsable',
            icon: 'reports',
            children: [
                {
                    id: 'report',
                    title: 'Custom Report',
                    type: 'basic',
                    icon: 'report',
                    link: '/custom-report'
                },
                {
                    id: 'snag-chart',
                    title: 'Snag Chart',
                    type: 'basic',
                    icon: 'snag_chart',
                    link: '/snag-chart'
                },
            ]
        },
        {
            id: 'export',
            title: 'Downloads',
            type: 'basic',
            icon: 'downloads',
            link: '/downloads'
        },
        {
            id: 'database',
            icon: 'data_management',
            title: 'Database',
            type: 'collapsable',
        },

    ];
}