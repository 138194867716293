import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { InitialData } from './organization.types';
import { OrganizationService } from 'app/core/service/organization/organization.service';

@Injectable({
    providedIn: 'root'
})
export class OrganizationDataResolver 
{
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _organizationService: OrganizationService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InitialData> {
        return this._organizationService.getOrganizationsInitial()
        .pipe(
            map((organizations) => ({
                organizations
            })
            ),
            catchError(err => {
                this._organizationService.errorMessage = err?.error?.message ? err?.error?.message : "Something went wrong !";
                return of(new DefaultResponse().organizations);
            })
        );
        
    }

}
class DefaultResponse implements InitialData {
    organizations: any = [];
}