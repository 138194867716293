import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { InitialData } from './checklist-submission.types';
import { ChecklistService } from 'app/core/service/checklist/checklist.service';

@Injectable({
    providedIn: 'root'
})
export class ChecklistSubmissionDataResolver 
{
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _checklistService: ChecklistService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return forkJoin([
            this._checklistService.getChecklistSubmissionsInitial(route.params['id']),
            this._checklistService.getChecklistDetails(route.params['id'])
        ]);
    }

}
class DefaultResponse implements InitialData {
    checklists: any = [];
}