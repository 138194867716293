import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FuseLoadingBarModule } from '@fuse/components/loading-bar';
import { SharedModule } from 'app/shared/shared.module';
import { PDFLayoutComponent } from 'app/layout/layouts/pdf/pdf.component';

@NgModule({
    declarations: [
        PDFLayoutComponent
    ],
    imports     : [
        RouterModule,
        FuseLoadingBarModule,
        SharedModule
    ],
    exports     : [
        PDFLayoutComponent
    ]
})
export class PDFLayoutModule
{
}
