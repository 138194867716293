import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of, forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { OrganizationService } from 'app/core/service/organization/organization.service';
import { InitialData } from './event-details.types';
import { EventConfigurationService } from 'app/core/service/event/event-config.service';
import { ProjectService } from 'app/core/service/project/project.service';
import { LocalStorageService } from 'app/core/service/localstorage/local-storage.service';
import { FloorPlanService } from 'app/core/service/project/floor-plan.service';
import { EventService } from 'app/core/service/event/event.service';
import { FactoryService } from 'app/core/service/factory/factory.service';

@Injectable({
    providedIn: 'root'
})
export class EventDetailDataResolver  {
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _eventConfigurationService: EventConfigurationService,
        private _projectService: ProjectService,
        private _organizationService: OrganizationService,
        private _localStorage: LocalStorageService,
        private _eventService: EventService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return forkJoin([
            this._eventService.getEventHistory(route.params['id']),
            this._eventService.getEventDetail(route.params['id'])
                .pipe(
                    map((event) => ({
                        event
                    })
                    ),
                    catchError(err => {
                        return of(new DefaultResponse().event);
                    })
                ),
            this._eventConfigurationService.getEventCategories(0, 100, null, null, null)
                .pipe(
                    map((eventCategories) => ({
                        eventCategories
                    })
                    ),
                    catchError(err => {
                        return of(new DefaultResponse().eventCategories);
                    })
                ),
            this._projectService.getProjects(0, 100, null, null, null)
                .pipe(
                    map((projects) => ({
                        projects
                    })
                    ),
                    catchError(err => {
                        return of(new DefaultResponse().projects);
                    })
                ),
            this._organizationService.getActionParties(),
            this._organizationService.getConfigurations(this._localStorage.organizationId)
                .pipe(
                    map((config) => ({
                        config
                    })
                    ),
                    catchError(err => {
                        return of(new DefaultResponse().config);
                    })
                ),
            this._eventConfigurationService.getInjuryTypes(0, 100, null, null, null)
                .pipe(
                    map((injuryTypes) => ({
                        injuryTypes
                    })
                    ),
                    catchError(err => {
                        return of(new DefaultResponse().injuryTypes);
                    })
                ),
            // this._eventConfigurationService.getRootCauses(0, 100, null, null, null)
            //     .pipe(
            //         map((rootCauses) => ({
            //             rootCauses
            //         })
            //         ),
            //         catchError(err => {
            //             return of(new DefaultResponse().rootCauses);
            //         })
            //     ),
        ]);
    }

}
class DefaultResponse implements InitialData {
    eventCategories: any = [];
    projects: any = []
    employees: any = []
    rootCauses: any[]
    injuryTypes: any[]
    config: any
    event: any
}