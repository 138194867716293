import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { MessagesService } from 'app/layout/common/messages/messages.service';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { QuickChatService } from 'app/layout/common/quick-chat/quick-chat.service';
import { ShortcutsService } from 'app/layout/common/shortcuts/shortcuts.service';
import { UserService } from 'app/core/user/user.service';
import { EventConfigurationService } from './core/service/event/event-config.service';
import { LocalStorageService } from './core/service/localstorage/local-storage.service';
import { OrganizationService } from './core/service/organization/organization.service';
import { DownloadsService } from './layout/common/downloads/downloads.service';

@Injectable({
    providedIn: 'root'
})
export class InitialDataResolver  {
    /**
     * Constructor
     */
    constructor(
        private _userService: UserService,
        private _organizationService: OrganizationService,
        private _config: EventConfigurationService,
        private _downloads: DownloadsService,
        private _localStorage: LocalStorageService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Use this resolver to resolve initial mock-api for the application
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        let requestArray = []
        requestArray.push(this._userService.get())
        if (this._localStorage.accountType != 'super_admin') {
            requestArray.push(this._config.getEventCategoriesInitial())
            requestArray.push(this._organizationService.getMyOrganization())
        }
        // Fork join multiple API endpoint calls to wait all of them to finish
        return forkJoin(requestArray);
    }
}
