import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { FactoryService } from 'app/core/service/factory/factory.service';

@Injectable({
    providedIn: 'root'
})
export class FactoryDataResolver  {
    /**
     * Constructor
     */
    constructor(private _factoryService: FactoryService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

        return forkJoin([
            this._factoryService.getFactoriesInitial(),
        ])

    }

}