import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { CommonModule, DatePipe, NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterLink } from '@angular/router';
import { ReportRequest } from 'app/core/service/report/report.model';
import { ReportService } from 'app/core/service/report/report.service';
import { Subject, takeUntil } from 'rxjs';
import { DownloadsService } from './downloads.service';
import { FileService } from 'app/core/service/file-services/file.service';

@Component({
    selector: 'downloads',
    templateUrl: './downloads.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'downloads',
    standalone: true,
    imports: [CommonModule, MatButtonModule, NgIf, MatProgressSpinnerModule, MatIconModule, MatTooltipModule, NgFor, NgClass, NgTemplateOutlet, RouterLink, DatePipe],
})
export class DownloadsComponent implements OnInit, OnDestroy {
    @ViewChild('downloadsOrigin') private _downloadsOrigin: MatButton;
    @ViewChild('downloadsPanel') private _downloadsPanel: TemplateRef<any>;

    downloads: ReportRequest[];
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _reportService: DownloadsService,
        private _overlay: Overlay, private _fileService: FileService,
        private _viewContainerRef: ViewContainerRef,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ) {
        this.matIconRegistry.addSvgIcon(
            "nav_downloads_icon",
            this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/images/nav_downloads_icon.svg")
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to download changes
        this._reportService.downloads$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((reportRequests: ReportRequest[]) => {
                // Load the downloads
                this.downloads = reportRequests;

                // Calculate the unread count
                // this._calculateUnreadCount();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
        // this._reportService.hasMoreOrders$
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((hasMore: boolean) => {
        //         this.hasMoreItems = hasMore;

        //         this._changeDetectorRef.markForCheck();
        //     });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    // hasMoreItems = false
    showLoading = false
    // loadNextPage() {
        // this.showLoading = true
        // this._reportService.getAll(true).subscribe({
        //     complete: () => { this.showLoading = false }
        // });
    // }

    downloadReport(download) {
        download.isDownloading = true
        download.downloadError = false
        this._fileService.downloadFile(download.download_url, download.title + ".pdf").subscribe({
            next: res => {
                download.isDownloading = false
                download.downloadError = false
            },
            error: err => {
                download.isDownloading = false
                download.downloadError = true
            }
        })
    }

    deleteReport(download) {

    }

    refreshReport(download) {

    }

    // refreshAll() {
    //     this._reportService.getAll().subscribe()
    // }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the downloads panel
     */
    openPanel(): void {
        // Return if the downloads panel or its origin is not defined
        if (!this._downloadsPanel || !this._downloadsOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._downloadsPanel, this._viewContainerRef));
    }

    /**
     * Close the downloads panel
     */
    closePanel(): void {
        this._overlayRef.detach();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(this._downloadsOrigin._elementRef.nativeElement)
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom',
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom',
                    },
                ]),
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

}
