import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { InitialData } from './event.types';
import { EventService } from 'app/core/service/event/event.service';
import { EventConfigurationService } from 'app/core/service/event/event-config.service';
import { ProjectService } from 'app/core/service/project/project.service';

@Injectable({
    providedIn: 'root'
})
export class EventDataResolver  {
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _eventService: EventService,
        private _eventConfigurationService: EventConfigurationService,
        private _projectService: ProjectService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        // return this._eventService.getEventsInitial(route.params['categoryId'])
        //     .pipe(
        //         map((events) => ({
        //             events
        //         })
        //         ),
        //         catchError(err => {
        //             this._eventService.errorMessage = err?.error?.message ? err?.error?.message : "Something went wrong !";
        //             return of(new DefaultResponse().events);
        //         })
        //     );

        return forkJoin([
            this._eventService.getEventsInitial(route.params['categoryId'])
                .pipe(
                    map((events) => ({
                        events
                    })
                    ),
                    catchError(err => {
                        this._eventService.errorMessage = err?.error?.message ? err?.error?.message : "Something went wrong !";
                        return of(new DefaultResponse().events);
                    })
                ),
            this._eventConfigurationService.getEventCategoryTypes(route.params['categoryId'], 0, 100, null, null, null)
                .pipe(
                    map((eventCategories) => ({
                        eventCategories
                    })
                    ),
                    catchError(err => {
                        return of(new DefaultResponse().eventCategories);
                    })
                ),
            this._projectService.getProjects(0, 100, null, null, null)
                .pipe(
                    map((projects) => ({
                        projects
                    })
                    ),
                    catchError(err => {
                        return of(new DefaultResponse().projects);
                    })
                ),
            this._eventConfigurationService.getStatusList()
        ]);

    }

}
class DefaultResponse implements InitialData {
    events: any = [];
    eventCategories: any = [];
    projects: any = [];
}