import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of, forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { OrganizationService } from 'app/core/service/organization/organization.service';
import { EventConfigurationService } from 'app/core/service/event/event-config.service';
import { ProjectService } from 'app/core/service/project/project.service';
import { LocalStorageService } from 'app/core/service/localstorage/local-storage.service';
import { FloorPlanService } from 'app/core/service/project/floor-plan.service';
import { EventService } from 'app/core/service/event/event.service';
import { FactoryService } from 'app/core/service/factory/factory.service';
import { InitialData } from '../event-details/event-details.types';

@Injectable({
    providedIn: 'root'
})
export class EventDetailGuestDataResolver  {
    /**
     * Constructor
     */
    constructor(private _eventService: EventService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return forkJoin([
            this._eventService.getEventDetailsForGuest(route.params['id'])
                .pipe(
                    map((event) => ({
                        event
                    })
                    ),
                    catchError(err => {
                        return of(new DefaultResponse().event);
                    })
                ),
        ]);
    }

}
class DefaultResponse implements InitialData {
    eventCategories: any = [];
    projects: any = []
    employees: any = []
    rootCauses: any[]
    injuryTypes: any[]
    config: any
    event: any
}