import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { InitialData } from './organization-document.types';
import { OrganizationService } from 'app/core/service/organization/organization.service';
import { DocumentService } from 'app/core/service/document/document.service';

@Injectable({
    providedIn: 'root'
})
export class OrganizationDocumentDataResolver 
{
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _documentService: DocumentService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InitialData> {
        return this._documentService.getDocumentsInitial(null)
            .pipe(
                map((projects) => ({
                    projects
                })
                ),
                catchError(err => {
                    this._documentService.errorMessage = err?.error?.message ?? "Something went wrong !";
                    return of(new DefaultResponse().documents);
                })
            );

    }

}
class DefaultResponse implements InitialData {
    documents: any = [];
}