import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EventConfigurationService } from 'app/core/service/event/event-config.service';

@Injectable({
    providedIn: 'root'
})
export class DashboardDataResolver 
{
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _eventService: EventConfigurationService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return forkJoin([
            this._eventService.getEventCategoriesInitial()
                .pipe(
                    map((categories) => ({
                        categories
                    })
                    ),
                    catchError(err => {
                        this._eventService.errorMessage = err?.error?.message ? err?.error?.message : "Something went wrong !";
                        return of([]);
                    })
                )
        ])

    }

}